import {
  Fragment,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { getStoredLang, removeStoredLang, setStoredCountry, setStoredLang } from "./storage";
import { api, routes } from "helpers/api";
import { IconAUFlag, IconIRLFlag, IconSPFlag, IconSPmxFlag, IconUsFlag } from "UIKit";
import { getSubDomain } from "Utils/domains";
import { observer } from "Utils/observer";

export const langContext = createContext<{
  isLoading: boolean;
  langId: string;
  langIndex: number;
  country: string;
  countries: Country[];
  setSelectedLangs: (langs: string[]) => void;
  setLang: (id: number | string, defaultLang?: string) => void;
  $: (params?: string, ...args: Elem[]) => Elem;
  getLangs: () => Langs;
  dd: () => {
    selected: string,
    onChange: (id: string | number) => void,
    list: Langs
  },
  setCountry: (key: string) => void;
  getCountries: () => Countries;
  ddCountries: () => {
    selected: string,
    onChange: (id: number | string) => void,
    list: Countries
  },
  getSelectedLang: () => SelectedLang;
}>({
  isLoading: true,
  langId: '',
  langIndex: 0,
  country: '',
  countries: [],
  setSelectedLangs: () => { },
  setLang: () => { },
  $: () => <></>,
  getLangs: () => [],
  dd: () => ({
    selected: '',
    onChange: () => { },
    list: []
  }),
  setCountry: () => { },
  getCountries: () => [],
  ddCountries: () => ({
    selected: '',
    onChange: () => { },
    list: []
  }),
  getSelectedLang: () => (
    {
      lang: '',
      country: '',
      flag: <></>
    }
  )
});

const Provider = langContext.Provider;

export const useLang = () => {
  const data = useContext(langContext);
  return { ...data };
};

type Keys = {
  [key: string]: string[]
}

type SelectedLang  = {
  lang: string;
  country: string;
  flag: Elem;
}

type Lang = {
  _id: string;
  index: number;
  national: string;
  gtm: string;
  key: keyof Flags;
}

type Langs = {
  id: string,
  value: string,
  gtm: string
}[]

type Country = {
  _id: string;
  national: string;
  key: keyof Flags;
  url: string;
  gtm: string;
}

type Countries = {
  id: string,
  value: string,
  icon: Elem,
  gtm: string
}[]

const flags: Flags = {
  "us": <IconUsFlag />,
  "mx": <IconSPmxFlag />,
  "sp": <IconSPFlag />,
  "au": <IconAUFlag />,

  "en": <IconUsFlag />,

  "sp-mx": <IconSPmxFlag />,
  "en-irl": <IconIRLFlag />,
  "en-au": <IconAUFlag />,
}

export const LangProvider = ({
  children
}: {
  children: Elem
}) => {
  const [keys, setKeys] = useState<Keys>({});
  const [langs, setLangs] = useState<Lang[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [lang, setLang] = useState<string>(getStoredLang());
  const [country, setCountry] = useState('');
  const [selectedLangs, setSelectedLangs] = useState<string[]>([]);

  const langIndex = langs.find(l => l._id === lang)?.index || 0;

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const { domain, subDomain } = getSubDomain();

    const keysList = await api.get(routes.server + "/translation/survey-new");
    const langsList = await api.get(routes.server + "/translation/langs");
    const countriesList = await api.get(routes.server + "/translation/countries/env", {
      params: {domain, subDomain}
    });

    setKeys(keysList);
    setLangs(langsList);
    setCountries(countriesList);
    setIsLoading(false);
  }

  const setLangById = (id: string) => {
    const selected = langs.find(l => l._id === id) || lang[0];
    if(selected) {
      observer.shout("region_picker_select", { 
        lang: langs.find(l => l._id === id)?.key,
        country: countries.find(c => c._id === country)?.key
      })
      
      setStoredLang(id);
      setLang(id);
    }
  }

  const getSelectedLang = (): SelectedLang => (
    {
      lang: (langs[langIndex]?.key as string || '').toUpperCase(),
      country: countries.find(i => i._id === country)?.national || '',
      flag: flags[countries.find(i => i._id === country)?.key] || <></>
    }
  )



  const value = {
    getSelectedLang,
    isLoading,
    langId: lang,
    country,
    langIndex,
    countries,
    setLang: (id: string | number, defaultLang: string = '') => {
      if (id) {
        setLangById(id as string);
      } else {
        setLangById(defaultLang);
      }
    },

    setSelectedLangs,
    $: (params?: string, ...args: Elem[]) => {
      if (!Object.keys(keys).length) {
        return "";
      }
      if (!keys) {
        return "";
      }
      if (!params) {
        return "";
      }
      const [key, extra] = params.split(":");

      if (extra) {
        args.push(extra);
      }

      let value = (keys[key] && keys[key][langIndex]) || `*${key}*`;

      if (!keys[key]) {
        return value;
      }

      const mapped = value
        .replace(/({arg([0-9]+)})/gi, '||$1||')
        .replace(/(\*(.*)\*)/gi, '||$1||')
        .split('||')
        .reduce(
          (state: Elem[], val: string) => {
            const arg = val.match(/{arg([0-9]+)}/);
            const bold = val.match(/\*(.*)\*/);

            if (arg) {
              state.push(args[+arg[1] - 1]);
            } else if (bold) {
              state.push(<span className="lang_bold">{bold[1]}</span>);
            } else {

              state.push(val);
            }
            return state;
          },
          []
        );

      if (mapped.length === 1) {
        return mapped[0];
      }

      return mapped.map((m, i) => <Fragment key={i}>{m}</Fragment>);
    },
    getLangs: () => langs.filter(i => selectedLangs.find(id => i._id === id)).map(i => (
      {
        id: i._id,
        value: i.national,
        gtm: i.gtm
      }
    )),
    dd: () => (
      {
        selected: lang,
        onChange: (selected: string | number) => setLangById(selected as string),
        list: value.getLangs()
      }
    ),
    setCountry: (key: string) => {
      if(!countries.length) { return; }
      const { url, _id  } = countries.find(c => c._id === key) || countries[0];

      observer.shout("region_picker_select", { 
        lang: langs.find(l => l._id === lang)?.key,
        country: key
      })

      setStoredCountry(_id);
      setCountry(_id);
      
      if(url && !window.location.pathname.includes(`/${url}`)) {
        removeStoredLang();
        window.location.href = `/${url}`;
      }
      
    },
    getCountries: () => countries.map(i => (
      {
        id: i._id,
        value: i.national,
        icon: flags[i.key],
        gtm: i.gtm
      }
    )),
    ddCountries: () => (
      {
        selected: country,
        onChange: (selected: number | string) => value.setCountry(selected as string),
        list: value.getCountries()
      }
    ),
  };

  return <Provider value={value}>{children}</Provider>;
};
