import {
    SmallText, BtnSecondary, EvenBetween, IconBack, IconLogo,
    IconQuestion, Inner, LightBtn, Line, LinkBtn, MarkdownShow, SepLine, RowsFullHigh,
    Center, IconAbout, Short, Responsive, DropMenu, Text, H4, Dropdown, RowsFull, InnerSmall
} from 'UIKit';
import './Header.css';
import { useLang } from 'Lang/useLang';
import { useContext } from 'react';
import { stepsContext } from 'Questions/context/stepsContext';
import { useMarkdown } from 'Hooks/useMarkdown';
import { Legals } from 'Wrappers/Legals/Legals';
import { Support } from 'Wrappers/Support/Support';
import { observer } from 'Utils/observer';

export const Header = () => {
    const { handleBack, hasBack } = useContext(stepsContext);
    const { dd, $, ddCountries, getSelectedLang } = useLang();

    const fetch = useMarkdown('ABOUT_MYORCARE');

    const showAbout = () => {
        window.popup({
            elem: (
                <InnerSmall>
                    <RowsFullHigh>
                        <MarkdownShow fetch={fetch} />
                        <SepLine />
                        <Legals />
                        <SepLine />
                        <Center>
                            <SmallText gray light>{$('ABOUT_RIGHTS')}</SmallText>
                        </Center>
                    </RowsFullHigh>
                </InnerSmall>
            )
        });
    }

    const showSupport = () => {
        window.popup({
            elem: (
                <RowsFullHigh>
                    <Support />
                </RowsFullHigh>
            )
        });
    }

    const Pack = () => (
        <>
            <LightBtn isResponsive i={IconBack} onClick={handleBack} disabled={!hasBack} >{$('HEADER_BACK')}</LightBtn>
            <LinkBtn isResponsive i={IconQuestion} onClick={showSupport}>{$('HEADER_SUPPORT')}</LinkBtn>
            <BtnSecondary isResponsive onClick={showAbout} i={IconAbout} >{$('HEADER_ABOUT')}</BtnSecondary>
        </>
    )

    const handleOpen = () => {
        observer.shout("region_picker_open", true);
    }

    return (
        <nav className='Header'>
            <Inner>
                <EvenBetween>
                    <div>
                        <Responsive
                            wide={<Line><Pack /></Line>}
                            med={<Short><Pack /></Short>}
                        />
                    </div>
                    <IconLogo />
                    <Line>
                        <DropMenu
                            onOpen={handleOpen}
                            menu={(
                                <RowsFullHigh>
                                    <Text bold>{$('SELECT_COUNTRY_HEADER')}</Text>
                                    <RowsFull>
                                        <H4 bold>{$('SELECT_COUNTRY_COUNTRY')}</H4>
                                        <Dropdown {...ddCountries()} isSpread />
                                    </RowsFull>
                                    <RowsFull>
                                        <H4 bold>{$('SELECT_COUNTRY_LANG')}</H4>
                                        <Dropdown {...dd()} isSpread />
                                    </RowsFull>
                                </RowsFullHigh>
                            )}
                        >
                            <Line>
                                {getSelectedLang().flag}
                                <Text>{getSelectedLang().lang}</Text>
                            </Line>
                        </DropMenu>
                    </Line>
                </EvenBetween>
            </Inner>
        </nav>
    )
}