import { ReactComponent as Logo } from 'Assets/SVG/logo.svg';

import { ReactComponent as ArrowR } from 'Assets/Icons/arrow-r.svg';
import { ReactComponent as Rolling } from 'Assets/Gif/rolling.svg';
import { ReactComponent as RadioOn } from 'Assets/SVG/RadioOn.svg';
import { ReactComponent as CheckOn } from 'Assets/SVG/CheckOn.svg';
import { ReactComponent as ArrowDownCheck } from 'Assets/SVG/ArrowDownCheck.svg';
import { ReactComponent as Question } from 'Assets/SVG/question.svg';
import { ReactComponent as About } from 'Assets/SVG/about.svg';

import { ReactComponent as Back } from 'Assets/SVG/back.svg';
import { ReactComponent as Close } from 'Assets/SVG/close.svg';

import { ReactComponent as USFlag } from 'Assets/SVG/usFlag.svg';
import { ReactComponent as SPFlag } from 'Assets/SVG/spainFlag.svg';
import { ReactComponent as SPmxFlag } from 'Assets/SVG/mexicoFlag.svg';
import { ReactComponent as IRLFlag } from 'Assets/SVG/irelandFlag.svg';
import { ReactComponent as AUFlag } from 'Assets/SVG/auFlag.svg';

import { ReactComponent as Udi } from 'Assets/SVG/udi.svg';
import { ReactComponent as Ref } from 'Assets/SVG/ref.svg';
import { ReactComponent as MD } from 'Assets/SVG/md.svg';
import { ReactComponent as Factory } from 'Assets/SVG/factory.svg';
import { ReactComponent as ECRep } from 'Assets/SVG/ec-rep.svg';
import { ReactComponent as Classified } from 'Assets/SVG/classified.svg';

import { ReactComponent as Complete } from 'Assets/SVG/complete.svg';
import { ReactComponent as HighRisk } from 'Assets/SVG/highRisk.svg';
import { ReactComponent as LowRisk } from 'Assets/SVG/lowRisk.svg';
import { ReactComponent as Copy } from 'Assets/SVG/copy.svg';
import { ReactComponent as EmailAt } from 'Assets/Icons/email-at.svg';

import { ReactComponent as AI } from 'Assets/SVG/ai.svg';
import { ReactComponent as Dietitian } from 'Assets/SVG/dietitian.svg';
import { ReactComponent as Report } from 'Assets/SVG/report.svg';
import { ReactComponent as Done } from 'Assets/SVG/done.svg';
import { ReactComponent as Here } from 'Assets/SVG/here.svg';
import { ReactComponent as Dot } from 'Assets/SVG/dot.svg';

import { IConProps, Icon } from './Icon';
import { Text } from "UIKit";
import React from 'react';


export const IconLogo = ({ size }: IConProps) => {

    return (
        <Icon size='medium'>
            <Logo />
        </Icon>
    )
}

interface IIconText {
    icon: Elem,
    text: string
}

export const IconText = ({
    icon,
    text
}: IIconText) => {
    const getContent = () => {
        return text.split(' ').map(t => (
            <React.Fragment key={t}>
                {<Text>{t}</Text>}
            </React.Fragment>
        ))
    }

    return (
        <div className='icon_text'>
            <div className='text'>{getContent()}</div>
            {icon}
        </div>
    )
}

export const IconLogoLarge = () => <IconLogo size={'large'} />
export const IconLogoMedium = () => <IconLogo size={'medium'} />
export const IconLogoSmall = () => <IconLogo size={'small'} />

export const IconArrowR = () => <ArrowR />
export const IconRadioOn = () => <RadioOn />
export const IconCheckOn = () => <CheckOn />
export const IconArrowDown = () => <ArrowDownCheck />
export const IconQuestion = () => <Question />
export const IconAbout = () => <About />
export const IconBack = () => <Back />
export const IconClose = () => <Close />


export const IconUsFlag = () => <USFlag />
export const IconSPFlag = () => <SPFlag />
export const IconSPmxFlag = () => <SPmxFlag />
export const IconIRLFlag = () => <IRLFlag />
export const IconAUFlag = () => <AUFlag />

export const GifRolling = () => <Rolling />

export const IconClassified = () => <Classified />
export const IconECRep = () => <ECRep />
export const IconFactory = () => <Factory />
export const IconMD = () => <MD />
export const IconRef = () => <Ref />
export const IconUdi = () => <Udi />

export const IconComplete = () => <Complete />
export const IconHighRisk = () => <HighRisk />
export const IconLowRisk = () => <LowRisk />

export const IconCopy = () => <Copy />
export const IconEmailAt = () => <EmailAt />

export const IconAI = () => <AI />
export const IconDietitian = () => <Dietitian />
export const IconReport = () => <Report />
export const IconDone = () => <Done />
export const IconHere = () => <Here />
export const IconDot = () => <Dot />

