import { answersContext } from "Context/answersContext";
import { domainContext } from "Context/domainContext";
import { getIsComplete, stepsContext } from "Questions/context/stepsContext";
import { useContext, useEffect, useState } from "react";
import { useInput } from "./useInput";
import { useNavigate, useParams } from "react-router-dom";
import { useLang } from "Lang/useLang";
import { mapToML } from "dto/ml";
import { api, routes } from "helpers/api";
import { observer } from "Utils/observer";
import { COUNTRIES } from "types/countries";
import { LINKS } from "types/links";
import { getCookie } from "helpers/storage";

export const useResults = () => {
    const { clearCache } = useContext(stepsContext);
    const { answers, result, setResult } = useContext(answersContext);
    const data = useContext(domainContext);
    const [error, setError] = useState(false);
    const { langIndex, country } = useLang();

    const isLoading = result.short === '';
    const myorLink = process.env.REACT_APP_LANDING_PORT!
    const myorAppointmentsLink = (() => {
        const base = process.env.REACT_APP_LANDING_PORT!;

        switch (country) {
            case COUNTRIES.AUSTRALIA: return base;
            case COUNTRIES.US: return LINKS.ZOCDOC;
            default: return base + LINKS.APPOINTMENT;
        }
    })()
    const returnToLink = data?.returnToLink || myorLink;
    const nextToLink = data?.nextToLink || myorLink + "/post-survey"; //survey link
    
    const Code = useInput("");
    Code.value = result.short;

    const { domain } = useParams();
    const navigate = useNavigate();


    useEffect(() => {
        if (isLoading) {
            setTimeout(() => {
               processCode();
            }, 1000)
        }
    }, [isLoading])

    const processCode = () => {
        const body = mapToML(answers, data!);
        const ga = getCookie("_ga");

        if (body) {
            api.post(routes.ml!, {
                ml: body,
                info: answers,
                data: {
                    ...data,
                    lang: langIndex,
                    ga
                }
            })
                .then((resp): any => {
                    observer.shout('Survey-Complete', resp);
                    setResult({ ...resp, answers: { ...answers } });
                    clearCache();
                }).catch(() => {
                    setError(true)
                })
        }
    }

    const handleNext = () => {
        navigate(`/${domain}/schedule`);
    }

    return {
        isComplete: getIsComplete(),
        handleNext,
        returnToLink,
        nextToLink,
        myorLink,
        myorAppointmentsLink,
        error,
        isLoading,
        domain,
        Code
    }
}