import { Center, Line } from "UIKit/Layouts/Line/Line"
import { Cube } from "../Cube/Cube"
import { IconAI, IconDietitian, IconDone, IconDot, IconHere, IconReport } from "../Icon"
import './StepsSummary.css';
import { Text } from "../Typography/Typography";

export const StepsSummary = () => {
    return (
        <div className="StepsSummary">
            <div className="wrap">
                <div>
                    <Cube
                        variant="done"
                        icon={IconAI}
                        title='AI - Powered Recommendations'
                    />
                </div>
                <div>
                    <Cube
                        variant="default"
                        icon={IconDietitian}
                        title='Expert Dietitian Consultation'
                    />
                </div>
                <div>
                    <Cube
                        variant="next"
                        icon={IconReport}
                        title='Evidence Based Preventive Treatments'
                    />
                </div>
            </div>
            <div className="wrap">
                <div>
                    <IconDone />
                </div>
                <div>
                    <IconHere />
                </div>
                <div>
                    <IconDot />
                </div>
            </div>
            <div className="wrap">
                <div>
                    <Text>Done</Text>
                </div>
                <div>
                    <Text bold>You are here</Text>
                </div>
                <div>
                    <Text>Next Step</Text>
                </div>
            </div>
        </div>
    )
}