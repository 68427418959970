import App from "App"
import { domainContext } from "Context/domainContext"
import { useLang } from "Lang/useLang"
import { Complete } from "Views/Complete"
import { Followup } from "Views/Followup"
import { Results } from "Views/Results"
import { Schedule } from "Views/Schedule"
import { SurveyDone } from "Views/SurveyDone"
import { useContext, useEffect } from "react"
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom"


export const Routes = () => {
    const data = useContext(domainContext);
    const lang = useLang();

    useEffect(() => {
        if(!data?.isLoading && !lang.isLoading) {
            lang.setLang(lang?.langId, data?.defaultLang)
            lang.setSelectedLangs(data?.selectedLangs || [])
            lang.setCountry(data?.countryId || '')
        }
    }, [data?.isLoading, lang.isLoading])
    
    const router = createBrowserRouter([
        {
            path: '/:domain/followup',
            element: <Followup />
        },
        {
            path: '/:domain/:subDomain/followup',
            element: <Followup />
        },


        {
            path: '/:domain/survey_done',
            element: <SurveyDone />
        },
        {
            path: '/:domain/:subDomain/survey_done',
            element: <SurveyDone />
        },


        {
            path: '/:domain/complete',
            element: <Complete />
        },
        {
            path: '/:domain/:subDomain/complete',
            element: <Complete />
        },


        {
            path: '/:domain/results',
            element: <Results />
        },
        {
            path: '/:domain/:subDomain/results',
            element: <Results />
        },


        {
            path: '/:domain/schedule',
            element: <Schedule />
        },
        {
            path: '/:domain/:subDomain/schedule',
            element: <Schedule />
        },


        {
            path: '/:domain',
            element: <App />
        },
        {
            path: '/:domain/:subDomain',
            element: <App />
        },

        {
            path: '/',
            element: <Navigate to='/us' />
        },
    ])

    if(data?.isLoading) {
        return <></>
    }
    return (
        <RouterProvider router={router} fallbackElement={<h1>...</h1>} />
    )
}