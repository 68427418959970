import { H1 } from "UIKit/Elements/Typography/Typography";
import { Box } from "../Box/Box";

import './Step.css';
import { useLang } from "Lang/useLang";

interface IProps {
    header?: string;
    children: Elem;
    per?: number;
}
export const Step = ({ header, children, per }: IProps) => {
    const { $ } = useLang();

    return (
        <div className="Step">
            <Box>
                {
                    header && (
                        <div className="header">
                            <H1 center>{$(header)}</H1>
                        </div>
                    )
                }
                {
                    per && (
                        <div className="bar">
                            <div className="per" style={{ width: per + '%' }}>

                            </div>
                        </div>
                    )
                }
                <div className="content">
                    {children}
                </div>
            </Box>
        </div>
    )
}