
import { Center, Text, Rows, Step, Padding, Btn, RowsFull, SepLine, IconComplete, H1, BtnCancel, Wrap, CenterAlign, StepsSummary, MarginTop } from 'UIKit';
import { HomeLayout } from 'Screens/HomeLayout/HomeLayout';
import { useLang } from 'Lang/useLang';
import { Link } from 'react-router-dom';
import { useResults } from 'Hooks/useResults';
import { ResultsLoader } from './components/ResultsLoader';


export const Complete = () => {
    const { $ } = useLang();
    const {
        myorAppointmentsLink,
        nextToLink,
        error,
        isLoading,
    } = useResults();

    return (
        <div className="App">
            <HomeLayout>
                <Center>
                    <Rows>
                        <Step>
                            <Wrap height={450}>
                                {
                                    !isLoading ? (
                                        <>
                                            <CenterAlign>
                                                <IconComplete />
                                            </CenterAlign>
                                            <H1 bold mark center>{$('COMPLETE_MESSAGE')}</H1>
                                            <Padding>
                                                <Rows>
                                                    <StepsSummary />
                                                    <MarginTop>
                                                        <Text center>{$('COMPLETE_ON_REVIEW')}</Text>
                                                    </MarginTop>
                                                </Rows>
                                            </Padding>
                                        </>
                                    ) :
                                        (
                                            <ResultsLoader error={error} />
                                        )
                                }
                            </Wrap>
                            <SepLine />
                            <Padding>
                                <RowsFull>
                                    <Link to={myorAppointmentsLink} className='contents'>
                                        <Btn onClick={() => { }}>{$('COMPLETE_ACTION_BACK')}</Btn>
                                    </Link>
                                    <Link to={nextToLink} className='contents'>
                                        <BtnCancel onClick={() => { }}>{$('COMPLETE_ACTION_NEXT')}</BtnCancel>
                                    </Link>
                                </RowsFull>
                            </Padding>
                        </Step>
                    </Rows>
                </Center>
            </HomeLayout>

        </div>
    );
}